import $ from 'jquery';

$(() => {

	// Price increment/decrement buttons on product pages
	$('.js-quantity-button').on('click', event => {
		event.preventDefault();

		const $button = $(event.currentTarget);
		const $quantity = $button.siblings('.js-quantity-amount');

		const currentQuantity = parseInt($quantity.val(), 10);

		if($button.hasClass('js-quantity-button--plus')) {
			$quantity.val(currentQuantity + 1);
		}
		else if($button.hasClass('js-quantity-button--minus')) {
			let minimum = 1;

			// Allow setting quantity to 0 on cart to remove product
			if($('body').hasClass('woocommerce-cart')) {
				minimum = 0;
			}

			if(currentQuantity - 1 >= minimum) {
				$quantity.val(currentQuantity - 1);
			}
		}

		// Enable update cart button
		$('.woocommerce-cart-form :input[name="update_cart"]').prop('disabled', false).attr('aria-disabled', false);
	});

});
