import $ from 'jquery';
import Swiper, { Navigation, Pagination } from 'swiper';

$(() => {

	Swiper.use([Navigation, Pagination]);

	// Home Delivery page
	new Swiper('.js-swiper-home-delivery', {
		grabCursor: true,
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev'
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets'
		}
	});

	// Slider component
	$('.js-slider').each((index, slider) => {
		const $slider = $(slider);

		const postType = $slider.attr('data-post-type');

		const settings =  {
			grabCursor: true,
			navigation: {
				nextEl: $slider.siblings('.swiper-button-next').get(0),
				prevEl: $slider.siblings('.swiper-button-prev').get(0)
			},
			pagination: {
				el: $slider.siblings('.swiper-pagination').get(0),
				type: 'bullets'
			},
			spaceBetween: 32,
			watchOverflow: true,
			breakpoints: {
				576: {
					slidesPerView: 2
				},
				992: {
					slidesPerView: 2.5
				},
				1200: {
					slidesPerView: 3.5
				}
			}
		};

		// Featured articles on posts page
		if($slider.hasClass('js-slider-featured-articles')) {
			settings.centeredSlides = true;
			settings.loop = true;
			settings.slidesPerView = 1.5;
			settings.breakpoints = {
				576: {
					slidesPerView: 2
				},
				992: {
					slidesPerView: 2.5
				},
				1200: {
					slidesPerView: 3.5
				}
			}
		}

		// Showcase component
		if($slider.hasClass('js-slider-showcase')) {
			settings.breakpoints = {
				576: {
					slidesPerView: 2
				},
				768: {
					slidesPerView: postType == 'post' ? 2 : 3
				}
			}
		}

		new Swiper(slider, settings);
	});

	// Testimonials component
	$('.js-swiper-testimonials').each((index, testimonials) => {
		const $buttons = $(testimonials).siblings('.js-swiper-testimonials__buttons');

		const nextButton = $buttons.find('.swiper-button-next').get(0);
		const prevButton = $buttons.find('.swiper-button-prev').get(0);
		const pagination = $buttons.find('.swiper-pagination').get(0);

		new Swiper(testimonials, {
			grabCursor: true,
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton
			},
			pagination: {
				el: pagination,
				type: 'fraction',
				renderFraction: (currentClass, totalClass) => `<span class="${currentClass}"></span> OF <span class="${totalClass}"></span>`
			},
			spaceBetween: 32
		});
	});

	// Instagram component
	$('#sb_instagram').addClass('swiper-container js-swiper-instagram');
	$('#sbi_images').addClass('swiper-wrapper');
	$('.sbi_item').addClass('swiper-slide');

	$('.js-swiper-instagram').each((index, instagram) => {
		const $buttons = $(instagram).siblings('.js-swiper-instagram__buttons');

		const nextButton = $buttons.find('.swiper-button-next').get(0);
		const prevButton = $buttons.find('.swiper-button-prev').get(0);

		new Swiper(instagram, {
			centeredSlides: true,
			grabCursor: true,
			loop: true,
			navigation: {
				nextEl: nextButton,
				prevEl: prevButton
			},
			slidesPerView: 2,
			breakpoints: {
				768: {
					slidesPerView: 4
				},
				1200: {
					slidesPerView: 6
				}
			}
		});
	});

	// Category listings
	new Swiper('.js-swiper-categories', {
		freeMode: true,
		grabCursor: true,
		slidesOffsetAfter: 32,
		slidesPerView: 2.5,
		breakpoints: {
			768: {
				allowTouchMove: true,
				slidesOffsetAfter: 32,
				slidesPerView: 4.5
			},
			1200: {
				allowTouchMove: false,
				grabCursor: false,
				slidesOffsetAfter: 0,
				slidesPerView: 6
			}
		}
	});

});
