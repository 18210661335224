import $ from 'jquery';

$(() => {

	let scrolling = false;

	window.addEventListener('scroll', () => {
		if(!scrolling) {
			window.requestAnimationFrame(() => {
				if(window.pageYOffset > 56) {
					$('#js-header').addClass('is-fixed');
				}
				else {
					$('#js-header').removeClass('is-fixed');
				}

				scrolling = false;
			});

			scrolling = true;
		}
	});

});
