import $ from 'jquery';

// Move cursor to end of text in input
// https://css-tricks.com/snippets/jquery/move-cursor-to-end-of-textarea-or-input/
$.fn.putCursorAtEnd = function() {
	return this.each(function() {
		// Cache references
		var $el = $(this),
			el = this;

		// Only focus if input isn't already
		if (!$el.is(':focus')) {
			$el.trigger('focus');
		}

		// If this function exists... (IE 9+)
		if (el.setSelectionRange) {
			// Double the length because Opera is inconsistent about whether a carriage return is one character or two.
			var len = $el.val().length * 2;

			// Timeout seems to be required for Blink
			setTimeout(function() {
				el.setSelectionRange(len, len);
			}, 1);
		}
		else {
			// As a fallback, replace the contents with itself
			// Doesn't work in Chrome, but Chrome supports setSelectionRange
			$el.val($el.val());
		}

		// Scroll to the bottom, in case we're in a tall textarea
		// (Necessary for Firefox and Chrome)
		this.scrollTop = 999999;
	});
};

$(() => {

	// Open/close search form
	$('.js-search-toggle').on('click', event => {
		const $search = $(event.currentTarget).closest('.js-search');
		$search.toggleClass('is-open');

		if($search.hasClass('is-open')) {
			$search.find('.search-field').putCursorAtEnd();
		}
	});

	// Close search form
	$('.js-search-close').on('click', event => {
		const $search = $(event.currentTarget).closest('.js-search');
		$search.removeClass('is-open');
	});

	// Focus search form
	$('.js-search-focus').on('click', event => {
		const $search = $(event.currentTarget).closest('.js-search');
		$search.find('.search-field').putCursorAtEnd();
	});

});
