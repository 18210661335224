import $ from 'jquery';
import 'justifiedGallery';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

$(() => {

	$('.js-gallery').justifiedGallery({
		rowHeight: 180,
		margins: 10
	});

	// Open PhotoSwipe gallery on thumbnail click
	$('.js-gallery-image').on('click', event => {
		event.preventDefault();

		const $thumbnail = $(event.currentTarget);

		// Build list of gallery items from siblings of (and including) clicked thumbnail
		const galleryItems = [];
		$thumbnail.siblings('.js-gallery-image').addBack().each((index, image) => {
			galleryItems.push({
				src: image.href,
				w: image.dataset.width,
				h: image.dataset.height,
				title: $(image).find('img').attr('alt')
			});
		});

		const gallery = new PhotoSwipe(document.getElementById('js-photoswipe'), PhotoSwipeUI_Default, galleryItems, {
			bgOpacity: 0.8,
			history: false,
			index: parseInt($thumbnail.attr('data-index'), 10)
		});
		gallery.init();
	});

});
