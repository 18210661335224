import $ from 'jquery';

$(() => {

	// Open specific drawer
	$('.js-drawer-open').on('click', event => {
		event.preventDefault();

		const drawerName = $(event.currentTarget).attr('data-drawer');

		$(`.js-drawer[data-drawer="${drawerName}"]`).addClass('is-open');
		$('#js-drawer-background').addClass('is-open');
	});

	// Close all drawers
	$('.js-drawer-close').on('click', event => {
		event.preventDefault();

		$('.js-drawer').removeClass('is-open');
		$('#js-drawer-background').removeClass('is-open');
	});

});
