import $ from 'jquery';

$(() => {

	// Every 5 seconds, fade out visible ribbon message and fade in next
	setInterval(() => {
		const $visibleMessage = $('.js-ribbon-message.is-visible');

		$visibleMessage.removeClass('is-visible');

		// Loop back to first message
		let $nextMessage = $visibleMessage.next();
		if(!$nextMessage.length) {
			$nextMessage = $('.js-ribbon-message').first();
		}

		// Fade in next message after short delay
		setTimeout(() => {
			$nextMessage.addClass('is-visible');
		}, 500);
	}, 5000);

});
