import $ from 'jquery';
import 'jquery-hoverintent/jquery.hoverIntent.js';

function openMegamenu() {
	$('#js-megamenu').addClass('is-open');

	const $header = $('#js-header');
	$header.addClass('is-megamenu-open');

	if($header.hasClass('o-header--white')) {
		$header.removeClass('o-header--white').addClass('js-was-white');
	}
}

function closeMegamenu() {
	$('#js-megamenu').removeClass('is-open');
	$('.js-megamenu-menu').removeClass('is-visible')

	const $header = $('#js-header');
	$header.removeClass('is-megamenu-open');

	if($header.hasClass('js-was-white')) {
		$header.removeClass('js-was-white').addClass('o-header--white');
	}
}

$(() => {

	$('.js-megamenu-open').hoverIntent(event => {
		const $megamenuOpen = $(event.currentTarget);
		const megamenuIndex = $megamenuOpen.attr('data-megamenu-index');

		const $megamenuToOpen = $(`.js-megamenu-menu[data-megamenu-index="${megamenuIndex}"]`);

		if($megamenuToOpen.length) {
			openMegamenu();

			// Activate specific megamenu inside megamenu
			$('.js-megamenu-menu').removeClass('is-visible')
			$megamenuToOpen.addClass('is-visible');
		}
		else {
			closeMegamenu();
		}
	});

	// Close megamenu on mouseout after delay
	$('#js-header').hoverIntent({
		over: event => null,
		out: event => {
			closeMegamenu();
		},
		timeout: 200
	});

});
