import $ from 'jquery';

$(() => {

	// Toggle mobile menu accordions
	$('.js-submenu-button').on('click', event => {
		const $submenuButton = $(event.currentTarget);

		$submenuButton.toggleClass('is-open');

		const $submenuContent = $submenuButton.siblings('.js-submenu-content');
		$submenuContent.slideToggle({
			start: animation => {
				$(animation.elem).css('display', 'flex');
			}
		});
	});

});
